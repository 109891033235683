<template>
    <div class="d-flex flex-column u-position-relative" :style="{ 'flex': 1, 'min-height': 0 }">
        <SelectedEventTicketCard
            v-if="selectedTicket"
            :ticket="selectedTicket"
            :quantity="ticketsQuantity"
            class="ma-2 mb-0"
            @change="$emit( 'change-event-ticket' )" />
        <AddHotelReservation
            :style="{ 'flex': 1, 'min-height': 0 }"
            :value="selectedHotelReservation"
            :event="event"
            :init-from-query="initFromQuery"
            :mobile-hotel-details-class="hotelDetailsClass"
            :start-date-time="startDateReservation"
            :end-date-time="endDateReservation"
            :default-view="$vuetify.breakpoint.mdAndUp ? 'list' : 'map'"
            :quantity="ticketsQuantity"
            :in-location="currentLocation"
            :distance-unit-of-measure="distanceUnitOfMeasure"
            :bundle-products="bundleProducts"
            :highlighted-item="highlightedHotelReservation"
            :limit="hotelsLimit"
            :range="hotelsRange"
            :read-only-filters="isPreAllocatedFlow"
            :load-only-first-limit="isPreAllocatedFlow"
            suppress-auto-highlight
            :allow-toggle-view="!$vuetify.breakpoint.mdAndUp"
            uniform-hotel-card
            @change-show-filter="$emit('change-show-filter', $event)"
            @highlight-item="$emit('highlight-hotel-reservation', $event)"
            @input="onInputDo"
            @hotels-loaded="$emit( 'hotels-loaded', $event )" />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { getDistanceUnitOfMeasureForCountry } from '@core/utils/measureUtils';
    import { addDays } from '@tenant/app/utils/dateUtils';
    import { create as CreateBundleProductsModel } from '@tenant/app/modelsV2/BundleProductsModel';
    import { validateSearchHotelsQueryParams } from '@tenant/app/utils/search/validateQuery';
    import EventFlowMixin from '@tenant/app/mixins/EventFlowMixin';
    import hotelsConstants from '@tenant/app/utils/constants/hotels';
    import AddHotelReservation from '@tenant/app/modules/hotel/features/addHotelReservation/AddHotelReservation';
    import SelectedEventTicketCard from './_components/SelectedEventTicketCard';

    export default {
        name: 'EventHotels',
        components: {
            AddHotelReservation,
            SelectedEventTicketCard
        },
        mixins: [
            EventFlowMixin
        ],
        props: {
            event: {
                type: Object,
                required: true,
            },
            isEventBannerVisible: {
                type: Boolean,
                default: true
            },
            selectedHotelReservation: {
                type: Object,
                default: null,
            },
            selectedTicket: {
                type: Object,
                default: null
            },
            ticketsQuantity: {
                type: Number,
                default: 2
            },
            busEventsParent: {
                type: Object,
                required: true
            },
            bundleProducts: {
                type: Object,
                default: function ( ) {
                    return CreateBundleProductsModel( );
                }
            },
            highlightedHotelReservation: {
                type: Object,
                default: null
            },
            isNoEvent: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            ...mapGetters( {
                // get location from the event. if event is unknown, the location will have
                // null properties and trigger search location modal
                eventLocation: `events/getEventLocation`,
                isEventUnknown: 'events/isUnknown',
                searchedLocation: 'addHotelReservationState/hotels/getLocation',
                hasCurrentLocation: 'addHotelReservationState/hasCurrentLocation'

            } ),
            currentLocation( ) {
                return this.hasCurrentLocation ? this.searchedLocation : this.eventLocation;
            },
            currency( ) {
                return this.event.currency;
            },
            startDateReservation( ) {
                return this.event.startDateTime || this.minBookingStartDate;
            },
            endDateReservation( ) {
                const { startDateTime, endDateTime } = this.event;

                const usedDate = endDateTime || startDateTime || this.minBookingStartDate;

                return addDays( usedDate, 1 );
            },
            distanceUnitOfMeasure( ) {
                return getDistanceUnitOfMeasureForCountry ( this.currentLocation.countryCode );
            },
            hotelDetailsClass( ) {
                return this.isEventBannerVisible ? 'v-dialog__height-80' : 'v-dialog__height-99';
            },
            hotelsLimit( ) {
                return hotelsConstants.HOTEL_ONLY.limit;
            },
            hotelsRange( ) {
                return hotelsConstants.HOTEL_ONLY.range;
            },
            initFromQuery( ) {
                return validateSearchHotelsQueryParams( this.$route.query, { minStartDate: this.minBookingStartDate } ) && this.isNoEvent;
            }
        },
        methods: {
            onInputDo( item ) {
                this.$emit( 'select-hotel-reservation', item );
                this.$emit( 'highlight-hotel-reservation', item ? item.item : this.highlightedHotelReservation );
            }
        }
    };
</script>