<template>
    <div class="gst-event-content d-flex flex-column flex-md-row u-width-100 u-height-100" :style="{'min-height': 0 }">
        <div v-if="!isEventNotAvailable && $vuetify.breakpoint.mdAndUp" class="col col-md-8 col-lg-9 d-flex px-0 py-0 u-height-100 u-position-relative justify-center" :style="{'width' : '100%'}">
            <ContainerWithOverlay
                :style="{ maxWidth: selectedHotelReservation || highlightedHotelReservation ? '970px' : '100%'}"
                :absolute="true"
                color="#FFFFFF"
                opacity="0.6"
                :overlay="showHotelFilter"
                class="gst-event-ticket-detail-content__hotel-detail u-position-relative">
                <EventHotelsMap
                    v-if="hotelsReady"
                    :event="event"
                    :bundle-products="bundleProductsOnlyHotels"
                    :bus-events-parent="busEvent"
                    @highlight-hotel-reservation="setHighlightedHotelReservation" />
                <div v-if="selectedHotelReservation || highlightedHotelReservation"
                    class="gst-event-content__hotel-details u-height-100 d-flex flex-column">
                    <HotelDetailsV2
                        :has-value="!!highlightedHotelReservation || !!selectedHotelReservation"
                        :item="!selectedHotelReservation ? highlightedHotelReservation : selectedHotelReservation.item"
                        :event="event"
                        :bundle-products="bundleProductsOnlyHotels"
                        :bus-events-parent="busEvent"
                        class="flex-grow-1 u-overflow-scroll-y"
                        has-back-button
                        :back-button-text="$t( '_common:buttons.backToMap' )"
                        :in-location="searchedLocation"
                        @back="onHotelDetailsBackDo( )" />
                </div>
            </ContainerWithOverlay>
        </div>
        <div v-show="loadingHotels || !isEventNotAvailable" class="col col-md-4 col-lg-3 pa-0 ml-auto u-height-100" :style="{ 'min-height': 0 }">
            <EventSidebar
                class="u-position-relative"
                :disabled-button="!canSubmit"
                :display-footer="isFooterDisplayed"
                :footer-title="$t( `_components.eventSidebar.footer.titleTicketsOnly` )"
                :submit-button-placeholder="$t( '_components.eventSidebar.buttons.checkout' )"
                :bundle-products="bundleProducts"
                :event="event"
                @submit="onSubmitDo">
                <EventHotels
                    :event="event"
                    :is-no-event="isNoEvent"
                    :is-event-banner-visible="isEventBannerVisible"
                    :selected-hotel-reservation="selectedHotelReservation"
                    :selected-ticket="null"
                    :bus-events-parent="busEvent"
                    :bundle-products="bundleProductsOnlyHotels"
                    :highlighted-hotel-reservation="highlightedHotelReservation"
                    :tickets-quantity="2"
                    @change-show-filter="onChangeShowFilterHotelDo"
                    @select-hotel-reservation="selectHotelReservation"
                    @highlight-hotel-reservation="setHighlightedHotelReservation"
                    @hotels-loaded="onHotelsLoadedDo" />
            </EventSidebar>
        </div>
        <EventNotAvailable v-if="isEventNotAvailable"
            class="gst-event-content__event-not-available"
            :event="event"
            :is-offer-expired="isEventOfferExpired"
            :is-offer-sold-out="isEventOfferSoldOut" />
    </div>
</template>

<script>
    import Vue from 'vue';
    import cloneDeep from 'lodash/cloneDeep';
    import { mapActions, mapState, mapGetters } from 'vuex';
    import ContainerWithOverlay from '@core/shared/components/overlays/ContainerWithOverlay.vue';
    import eventConstants from '@core/utils/constants/event';
    import hotelsConstants from '@tenant/app/utils/constants/hotels';
    import tmEventConstants from '@tenant/app/utils/constants/event';
    import EventFlowMixin from '@tenant/app/mixins/EventFlowMixin';
    import { create as CreateBundleProductsModel } from '@tenant/app/modelsV2/BundleProductsModel';
    import { create as CreateBundleProductsHotelReservationModel } from '@tenant/app/modelsV2/BundleProductsHotelReservationModel';
    import { create as CreateBundleProductsEventTicketModel } from '@tenant/app/modelsV2/BundleProductsEventTicketModel';
    import HotelDetailsV2 from '@tenant/app/modules/hotel/components/containers/HotelDetailsV2.vue';
    import EventSidebar from './_components/EventSidebar.vue';
    import EventHotels from './EventContent/EventHotels.vue';
    import EventHotelsMap from './EventContent/EventHotelsMap.vue';

    const STORE_NAME = 'events';

    export default {
        name: 'EventContent',
        components: {
            EventSidebar,
            EventHotels,
            HotelDetailsV2,
            ContainerWithOverlay,
            EventHotelsMap,
            EventNotAvailable: ( ) => import( './EventNotAvailable' )
        },
        mixins: [
            EventFlowMixin,
        ],
        props: {
            activeTab: {
                type: String,
                default: 'hotels'
            },
            event: {
                type: Object,
                required: true
            },
            isEventBannerVisible: {
                type: Boolean,
                default: true
            },
            busEventParent: {
                type: Object,
                default: null
            },
            isNoEvent: {
                type: Boolean,
                default: false
            }
        },
        data( ) {
            return {
                busEvent: new Vue( ),
                bundleProducts: CreateBundleProductsModel( ),
                selectedHotelReservation: null,
                highlightedHotelReservation: null,
                showHotelFilter: false,
                venueDetails: {
                    mapUrl: '',
                    mapWithlabelsUrl: '',
                },
                tickets: [],
                hotelsFailedStatus: null,
                hotelsReady: false,
                isFirstLoad: true
            };
        },
        computed: {
            ...mapState( {
                loadingHotels: state => state.addHotelReservationState.hotels.loading,
                hotels: state => state.addHotelReservationState.hotels.list,
                productId: state => state.addHotelReservationState.hotels.productId,
                hotelFiltersAdultsCount: state => state.addHotelReservationState.hotels.filters.guests.adultsCount,
                hotelFilters: state => state.addHotelReservationState.filters
            } ),
            ...mapGetters( {
                isEventUnknown: `${STORE_NAME}/isUnknown`,
                initialLocation: 'addHotelReservationState/getLocation',
                searchedLocation: 'addHotelReservationState/hotels/getLocation',
                appLocale: 'appState/getLocale'
            } ),
            hasLocationChanged( ) {
                return this.initialLocation.longitude !== this.searchedLocation.longitude
                    || this.initialLocation.latitude !== this.searchedLocation.latitude;
            },
            isFooterDisplayed() {
                return !!this.selectedHotelReservation && !this.showHotelFilter;
            },
            canSubmit( ) {
                return !!this.selectedHotelReservation;
            },
            itemId( ) {
                return this.$route.params.id || this.event.id;
            },
            bundleProductsOnlyHotels( ) {
                return CreateBundleProductsModel(
                    CreateBundleProductsEventTicketModel(
                        { ...this.event },
                        null,
                        null,
                        null,
                        0,
                        0
                    ),
                    this.selectedHotelReservation ? CreateBundleProductsHotelReservationModel( this.selectedHotelReservation.item ) : null
                );
            },
            isEventTicketsStatusFewAvailable( ) {
                return this.event.ticketsStatus === eventConstants.TICKETS_STATUS.FEW_AVAILABLE;
            },
            isEventOfferSoldOut( ) {
                return this.hotelsReady && !this.loadingHotels && this.hotels.length === 0 && this.isPreAllocatedFlow;
            },
            isEventOfferExpired( ) {
                return !this.loadingHotels && this.hotelsFailedStatus === 400;
            },
            isEventNotAvailable( ) {
                return  !this.isEventUnknown && ( this.isEventOfferSoldOut || this.isEventOfferExpired );
            },
            showLoader( ) {
                return this.loadingHotels && !this.hotels.length && !this.selectedHotelReservation;
            },
            hotelsLimit( ) {
                return hotelsConstants.HOTEL_ONLY.limit;
            }
        },
        watch: {
            'selectedHotelReservation': function ( value ) {
                this.$emit( 'selected-hotel-reservation', value );
            },
        },
        methods: {
            ...mapActions( {
                loadOne                         : `${STORE_NAME}/one`,
                loadHotels                      : `addHotelReservationState/hotels/loadPage`,
                setEventFlow                  : `${STORE_NAME}/setEventFlow`,
            } ),
            onSubmitDo( ) {
                this.onGoToCheckoutDo( );
            },
            selectHotelReservation( hotelReservation ) {
                this.selectedHotelReservation = hotelReservation;

                this.bundleProducts = CreateBundleProductsModel(
                    null,
                    this.selectedHotelReservation ? CreateBundleProductsHotelReservationModel( hotelReservation.item ) : null
                );
            },
            setHighlightedHotelReservation( hotelReservation ) {
                this.highlightedHotelReservation = hotelReservation;
            },
            onGoToCheckoutDo( ) {
                const { selectedHotelReservation } = this;
                const room = selectedHotelReservation.item.selectedRoom;
                const roomOffer = room.selectedRate.offer;
                let payload = { };

                if ( selectedHotelReservation ) {
                    payload = {
                        hotelReservation: {
                            hotel: selectedHotelReservation.item,
                            room:  selectedHotelReservation.item.selectedRoom,
                            accommodation: {
                                startDateTime:  selectedHotelReservation.startDateTime,
                                endDateTime:    selectedHotelReservation.endDateTime,
                                roomsCount:     selectedHotelReservation.roomsCount,
                                guests:         selectedHotelReservation.guests,
                            },
                            originatingEventId: this.productId
                        },
                    };

                    if ( roomOffer ) {
                        payload.offer = {
                            id: roomOffer.id
                        };
                    }

                    if ( this.hasLocationChanged || this.isEventUnknown ) {
                        payload.searchedLocation = { ...this.searchedLocation };
                    }
                }
                this.$emit( 'checkout', payload );
            },
            onChangeSelectedHotelDo( ) {
                this.selectHotelReservation( null );
            },
            onRemoveSelectedHotelDo( ) {
                this.selectHotelReservation( null );
            },
            onChangeShowFilterHotelDo( value ) {
                this.showHotelFilter = value;
            },
            onSelectRoomDo( hotelReservation, filters ) {
                this.selectedHotelReservation = {
                    item: hotelReservation,
                    ...filters
                };

                this.bundleProducts.setHotelReservation( {
                    ...this.bundleProducts.hotelReservation,
                    hotel: hotelReservation
                } );
                this.bundleProducts.hotelReservation.setSelectedRoom( hotelReservation.selectedRoom );
            },
            updateSelectedHotelReservationSelectedRoomRate( newTotalPrice, oldTotalPrice, newGiftCardAmount, newRateId ) {
                const selectedHotelReservationWithNewSelectedRoomRate = cloneDeep( { ...this.selectedHotelReservation } );
                selectedHotelReservationWithNewSelectedRoomRate.item.selectedRoom.selectedRate.netPrice = newTotalPrice;
                selectedHotelReservationWithNewSelectedRoomRate.item.selectedRoom.selectedRate.totalPrice = newTotalPrice;
                selectedHotelReservationWithNewSelectedRoomRate.item.selectedRoom.selectedRate.totals.basePrice = newTotalPrice;
                selectedHotelReservationWithNewSelectedRoomRate.item.selectedRoom.selectedRate.oldTotalPrice = oldTotalPrice;
                if( !selectedHotelReservationWithNewSelectedRoomRate.item.selectedRoom.selectedRate.giftCard ) {
                    selectedHotelReservationWithNewSelectedRoomRate.item.selectedRoom.selectedRate.giftCard = {
                        amount: 0,
                        currency: selectedHotelReservationWithNewSelectedRoomRate.item.selectedRoom.selectedRate.currency
                    };
                }
                selectedHotelReservationWithNewSelectedRoomRate.item.selectedRoom.selectedRate.giftCard.amount = newGiftCardAmount;
                selectedHotelReservationWithNewSelectedRoomRate.item.selectedRoom.selectedRate.id = newRateId;
                this.selectHotelReservation( selectedHotelReservationWithNewSelectedRoomRate );
                this.setHighlightedHotelReservation( selectedHotelReservationWithNewSelectedRoomRate.item );
            },
            onEventHotelRoomRateChangedBackDo( ) {
                this.selectHotelReservation( null );

                this.loadHotels( {
                    refresh: true,
                    filters: this.hotelFilters,
                    limit: this.hotelsLimit
                } );
            },
            onEventHotelRoomRateChangedNextDo( newTotalPrice, oldTotalPrice, newGiftCardAmount, newRateId ) {
                this.updateSelectedHotelReservationSelectedRoomRate( newTotalPrice, oldTotalPrice, newGiftCardAmount, newRateId );
                this.onGoToCheckoutDo( );
            },
            onHotelDetailsBackDo( ) {
                this.setHighlightedHotelReservation( null );
                this.selectHotelReservation( null );
            },
            onHotelsLoadedDo( response ) {
                this.hotelsFailedStatus = response.success ? null : response.status;
                this.hotelsReady = true;
                // check if hotels have offers and if not switch to Open inventory flow
                if ( !this.hotelsFailedStatus && this.productId && this.hotels.length ) {
                    // hotels contains only hotels with guest rooms and room rates
                    const hasRoomRateOffer = !!( this.hotels[0].rooms[0].rates[0].offer && this.hotels[0].rooms[0].rates[0].offer.id );
                    if ( !hasRoomRateOffer && this.isFirstLoad ) {
                        // switch from pre-allocated to open inventory
                        this.setEventFlow( { flow: tmEventConstants.FLOWS.HOTELS_OPEN_INVENTORY } );
                        this.openOffersModal();
                        this.isFirstLoad = false;
                    }
                }
            },
            async openOffersModal ( ) {
                let modalContent = await this.$prismic.client.getSingle( 'hotel-benefits-and-discount-modal', { lang: this.appLocale } );
                if ( !modalContent ) {
                    modalContent = await this.$prismic.client.getSingle( 'hotel-benefits-and-discount-modal' );
                }
                if ( modalContent ) {
                    const title = modalContent.data.title;
                    const content = modalContent.data.content;
                    const buttonLabel = modalContent.data.button_label;
                    this.$modal.show(
                        ( ) => import( '@tenants/ticketmaster/app/components/modals/HotelOffersModal' ),
                        {
                            title,
                            content: content,
                            buttonLabel: buttonLabel
                        },
                        {
                            'width': 'unset'
                        }
                    );
                }
            },
        },
        created( ) {
            this.busEvent.$on( 'change-selected-hotel', this.onChangeSelectedHotelDo );
            this.busEvent.$on( 'remove-selected-hotel', this.onRemoveSelectedHotelDo );
            this.busEvent.$on( 'add-item', this.onSelectRoomDo );
            this.busEventParent.$on( 'event-hotel-room-rate-changed-back', this.onEventHotelRoomRateChangedBackDo );
            this.busEventParent.$on( 'event-hotel-room-rate-changed-next', this.onEventHotelRoomRateChangedNextDo );
        },
        destroyed( ) {
            this.busEvent.$off( 'change-selected-hotel' );
            this.busEvent.$off( 'remove-selected-hotel' );
            this.busEvent.$off( 'add-item' );
            this.busEventParent.$off( 'event-hotel-room-rate-changed-back' );
            this.busEventParent.$off( 'event-hotel-room-rate-changed-next' );
            this._listenerBrowserBack && this._listenerBrowserBack( );

            this._destroyed = true;
        }
    };
</script>


<style lang="scss">
@import "@scssVariables";
@import "@scssMixins";

.gst-event-ticket-detail-content__hotel-detail {
    .v-overlay {
        z-index: $z-index-event-page-over-layer + 1 !important;
    }
}

.gst-event-content__hotel-details {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $z-index-map-hotel-details;
}

.gst-event-content__event-not-available {
    position: absolute;
    width: 100%;
}
</style>
