<template>
    <HotelsMap
        class="flex-grow-1"
        :event="event"
        :bundle-products="bundleProducts"
        :bus-events-parent="busEvents"
        uniform-hotel-card />
</template>

<script>
    import Vue from 'vue';
    import { create as CreateBundleProductsModel } from '@tenant/app/modelsV2/BundleProductsModel';
    import { create as CreateBundleProductsHotelReservationModel } from '@tenant/app/modelsV2/BundleProductsHotelReservationModel';
    import HotelsMap from '@tenants/ticketmaster/app/modules/hotel/features/addHotelReservation/AddHotelReservation/HotelsMap.vue';

    export default {
        name: 'EventHotelMap',
        components: {
            HotelsMap
        },
        props: {
            event: {
                type: Object,
                required: true
            },
            busEventsParent: {
                type: Object,
                default: null
            },
            bundleProducts: {
                type: Object,
                required: true,
                default: function( ) {
                    return CreateBundleProductsModel( );
                }
            }
        },
        emits: [
            'highlight-hotel-reservation'
        ],
        data( ) {
            return {
                mapConfig: null,
                venueMarker: null,
                popupConfig: null,
                selectedHotel: null,
                busEvents: new Vue( )
            };
        },
        computed: {
            bundleProductsFinal( ) {
                const ret = this.bundleProducts._clone();

                ret.setHotelReservation(
                    CreateBundleProductsHotelReservationModel (
                        ret.hotelReservation.hotel
                    )
                );

                return ret;
            },
        },
        methods: {
            getFinalBundleProductsForHotelRoom( item ) {
                const ret = this.bundleProducts._clone( );

                ret.setHotelReservation(
                    CreateBundleProductsHotelReservationModel (
                        item
                    )
                );

                return ret;
            },
            openItemDetails( item, focusedRoom ) {
                if ( this.$vuetify.breakpoint.mdAndUp ) {
                    this.$emit( 'highlight-hotel-reservation', item );
                    return;
                }
                const modal = import( '@tenant/app/modules/hotel/components/containers/HotelDetailsV2' );

                const props = {
                    'no-click-animation': true,
                    persistent: false,
                    'max-width': 920,
                    'content-class': 'v-dialog__height-80 gst-v-dialog',
                    scrollable: true,
                    fullscreen: false,
                    'hide-overlay': false,
                    transition: 'dialog-bottom-transition'
                };
                const componentProps = {
                    event: this.event,
                    item,
                    focusedRoom,
                    bundleProducts: this.bundleProductsFinal,
                    hasValue: false,
                    busEventsParent: this.busEvents
                };

                this.$modal.showBottom( modal, componentProps, props );
            }
        },
        mounted() {
            this.busEvents.$on( 'open-item-details', this.openItemDetails );
        },
        destroyed() {
            this.busEvents.$off( 'open-item-details' );
        }
    };
</script>