<template>
    <div class="gst-event-totals-container d-flex u-width-100">
        <div class="d-flex flex-column flex-grow-1">
            <h4>{{ title }}:</h4>
            <p v-if="totalPrice" class="mb-0 text-lowercase">
                {{ selectedItemsWithQuantity }}
            </p>
        </div>
        <div v-if="totalPrice" class="d-flex flex-column flex-grow-1 text-right">
            <h4>{{ totalPrice | currencyFilter( currency ) }}</h4>
            <p class="mb-0">
                {{ pricePerPerson | currencyFilter( currency ) }}
                <span class="text-lowercase">
                    {{ $t( '_common:terms.perPerson' ) }}
                </span>
            </p>
        </div>
        <div v-else class="d-flex flex-column flex-grow-1 text-right gst-event-totals-container__no-price">
            <h4>{{ noSelectionPrice }}</h4>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'EventTotalsContainer',
        props: {
            title: {
                type: String,
                default: null
            },
            bundleProducts: {
                type: Object,
                default: null
            },
            event: {
                type: Object,
                required: true
            }
        },
        computed: {
            selectedHotel( ) {
                if ( this.bundleProducts.hasHotelReservation ( ) ) {
                    return this.bundleProducts.hotelReservation.hotel;
                }
                return null;
            },
            currency() {
                if ( this.selectedHotel ) {
                    return this.selectedHotel.selectedRoom ? this.selectedHotel.selectedRoom.selectedRate.currency : this.event.currency;
                }

                return this.event.currency;
            },
            adultsCount() {
                return this.selectedHotel && this.selectedHotel.selectedRoom.accommodation.guests.adultsCount || 0;
            },
            ticketsQuantity( ) {
                const { tickets } = this.bundleProducts.getQuantity( );

                return tickets;
            },
            totalPrice() {
                return this.bundleProducts.getTotals( ).basePrice;
            },
            pricePerPerson() {
                return ( this.totalPrice / ( this.adultsCount || this.ticketsQuantity ) );
            },
            noSelectionPrice() {
                const tempPrice = this.$options.filters.currencyFilter( 0, this.currency );
                const price = tempPrice.replace( '0.00', '-' );

                return price;
            },
            selectedItemsWithQuantity( ) {
                let items = [];

                if ( this.bundleProducts.hasHotelReservation( ) ) {
                    items.unshift( this.$t( '_common:terms.nightWithCount', { count: this.selectedHotel.selectedRoom.accommodation.nights } ) );
                }

                if ( this.ticketsQuantity ) {
                    items.push( this.$t( '_common:terms.ticketWithCount', { count: this.ticketsQuantity } ) );
                }

                return items.join( ' + ' );
            }
        }
    };
</script>

<style lang="scss" scoped>
@import "@scssVariables";
@import "@scssMixins";

.gst-event-totals-container {
    padding: theme-spacing( 4 );
    background: theme-color-hex( 'primary-lighten-4' );
    color: theme-color( 'primary' );
    z-index: 1;

    h4 {
        line-height: line-height( 'xxxxl' );
        font-size: font-size( 'l' );
        font-weight: font-weight( 'bold' );
    }

    p {
        line-height: line-height( 'm' );
        font-size: font-size( 'xxs' );
        font-weight: font-weight( 'regular' );
    }

    .gst-event-totals-container__no-price {
        min-height: 39px;
    }
}

@include mobile-only {
    .gst-event-totals-container {
        padding-top: theme-spacing( 2 );
        padding-bottom: theme-spacing( 2 );
    }
}
</style>